export default {
  activities: "Entrar Em Ação",
  healthcare: "Uso de serviços de saúde",
  homeTasks: "Atividade doméstica",
  voluntaryActivity: "Atividade voluntária",
  physicalActivity: "Atividades físicas de transporte e lazer",
  occupationalActivity: "Atividades ocupacionais",
  manual: "Inserir tempo manualmente",
  automatic: "Inserir tempo automaticamente",
};

export default {
  add: "Adicionar usuários",
  user: "Usuário",
  users: "Usuários",
  hair: "cabelo",
  skin: "pele",
  clothes: "roupas",
  others: "outros",
  name: "nome",
  age: "idade",
  illnesses: "doenças",
  medication: "medicações",
  weight: "Peso",
  waist: "Tamanho da cintura",
  arterialPressure: "Pressão arterial",
  height: "Altura",
  list: "Listar Usuários",
};


import { defineComponent } from "vue";

export default defineComponent({
  nome: "NavBarComponent",

  setup() {
    const baseItems = [
      {
        title: "groups",
        path: "/groups",
        icon: "mdi-account-group",
      },
      {
        title: "tips",
        path: "/tips",
        icon: "mdi-lightbulb",
      },
      {
        title: "prescription",
        path: "/prescription",
        icon: "mdi-medical-bag",
      },
      {
        title: "service",
        path: "/service",
        icon: "mdi-face-agent",
      },
    ];
    const userItems = [
      ...[
        {
          title: "user",
          path: "/user",
          icon: "mdi-account",
        },
        {
          title: "activities",
          path: "/activities",
          icon: "mdi-format-list-checkbox",
        },
        {
          title: "results",
          path: "/results",
          icon: "mdi-file",
        },
      ],
      ...baseItems,
    ];
    const adminItems = [
      ...[
        {
          title: "users",
          path: "/users",
          icon: "mdi-account",
        },
      ],
      ...baseItems,
    ];

    return {
      adminItems,
      userItems,
      logged: false,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SystemTray = _resolveComponent("SystemTray")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_InstallBanner = _resolveComponent("InstallBanner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_SignIn = _resolveComponent("SignIn")!
  const _component_v_app = _resolveComponent("v-app")!
  const _component_v_no_ssr = _resolveComponent("v-no-ssr")!

  return (_openBlock(), _createBlock(_component_v_no_ssr, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app, {
        theme: this.$store.state.theme
      }, {
        default: _withCtx(() => [
          (this.$store.getters.isLogged)
            ? (_openBlock(), _createBlock(_component_v_layout, {
                key: 0,
                style: {"z-index":"0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SystemTray),
                  _createVNode(_component_NavBar),
                  _createVNode(_component_v_navigation_drawer, {
                    class: "hidden-md-and-down",
                    width: _ctx.width
                  }, null, 8, ["width"]),
                  _createVNode(_component_v_navigation_drawer, {
                    class: "hidden-md-and-down",
                    width: _ctx.width,
                    location: "right"
                  }, null, 8, ["width"]),
                  _createVNode(_component_v_main, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_view, null, {
                        default: _withCtx(({ Component }) => [
                          _createVNode(_component_InstallBanner),
                          (_openBlock(), _createBlock(_KeepAlive, null, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                          ], 1024))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_router_view, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_InstallBanner),
                  _createVNode(_component_SignIn)
                ]),
                _: 1
              }))
        ]),
        _: 1
      }, 8, ["theme"])
    ]),
    _: 1
  }))
}
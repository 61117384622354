import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_banner = _resolveComponent("v-banner")!

  return (_ctx.deferredPrompt)
    ? (_openBlock(), _createBlock(_component_v_banner, {
        key: 0,
        color: "info",
        dark: "",
        class: "text-left"
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            text: "",
            color: "green",
            onClick: _ctx.install
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$vuetify.locale.t("install.confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            text: "",
            color: "red",
            onClick: _ctx.dismiss
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$vuetify.locale.t("install.dismiss")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$vuetify.locale.t("install.message")) + " ", 1)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
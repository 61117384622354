
import { defineComponent } from "vue";
import Cookies from "js-cookie";

export default defineComponent({
  name: "InstallBanner",

  data() {
    return {
      deferredPrompt: null,
    };
  },

  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();

      if ("" !== Cookies.get("add-to-home-screen")) {
        //@ts-expect-error: not able to handle it right now
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },

  methods: {
    async dismiss() {
      Cookies.set("add-to-home-screen", "", { expires: 15 });

      this.deferredPrompt = null;
    },
    async install() {
      //@ts-expect-error: not able to handle it right now
      this.deferredPrompt.prompt();
    },
  },
});

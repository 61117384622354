export default {
  groups: "Groups",
  suggestion: "Suggestion",
  mine: "My groups",
  all: "All groups",
  register: "Register group",
  accept: "Accept",
  submit: "Submit",
  cancel: "Cancel",
  name: "Group name",
  responsible: "Responsible",
  link: "Link",
};

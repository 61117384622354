import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bb35ba0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    color: "white",
    class: "overflow"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$store.getters.isAdmin ? _ctx.adminItems : _ctx.userItems, (item) => {
        return (_openBlock(), _createBlock(_component_v_btn, {
          key: item.title,
          to: item.path
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.$vuetify.locale.t(`navbar.${item.title}`)
            }, null, 8, _hoisted_1),
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.icon), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ]),
    _: 1
  }))
}

import { callAPI } from "@/scripts/api";
import { defineComponent, Ref, ref } from "vue";
import { Store, useStore } from "vuex";

const auth = async (
  store: Store<unknown>,
  mobile: string,
  password: string,
  error: Ref<boolean>
): Promise<boolean> => {
  const response = await callAPI("users/auth/", "POST", {
    mobile,
    password,
  });

  if (undefined !== response && null !== response) {
    store.commit("setUser", response);

    return true;
  }

  error.value = true;

  return false;
};

export default defineComponent({
  name: "SignInComponent",

  setup() {
    const mobile = ref("");
    const password = ref("");
    const loading = ref(false);
    const store = useStore();
    const error = ref(false);

    return {
      error,
      mobile,
      password,
      loading,
      checkPassword: (mobile: string, password: string) =>
        auth(store, mobile, password, error),
      userRules: [
        (value: string) => !!value || "Necessário.",
        (value: string) =>
          (value && 9 === value.length) || "Tem que ser 9 dígitos",
      ],
      passwordRules: [
        (value: string) => !!value || "Necessário.",
        (value: string) =>
          (value && 8 === value.length) || "Tem que ser 8 dígitos",
      ],
    };
  },
});

export default {
  user: "User",
  users: "Users",
  groups: "Groups",
  tips: "Tips",
  activities: "Activities",
  results: "Results",
  prescription: "Guidelines",
  service: "Service",
};

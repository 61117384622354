export default {
  user: "User",
  hair: "hair",
  skin: "skin",
  clothes: "clothes",
  others: "others",
  name: "name",
  age: "age",
  illnesses: "illnesses",
  medication: "medication",
  weight: "Weight",
  waist: "Waist",
  arterialPressure: "Arterial Pressure",
  height: "Height",
};

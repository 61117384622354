export default {
  groups: "Grupos",
  suggestion: "Sugestão",
  mine: "Meus grupos",
  all: "Todos os grupos",
  register: "Cadastrar grupo",
  accept: "Aceitar",
  submit: "Enviar",
  cancel: "Cancelar",
  name: "Nome do grupo",
  responsible: "Reponsável",
  link: "Link",
};

export default {
  user: "Usuário",
  users: "Usuários",
  groups: "Grupos",
  tips: "Dicas",
  activities: "Entrar em Ação",
  results: "Resultados",
  prescription: "Orientações",
  service: "Atendimento",
};
